// libraries
import React from 'react';
import styled from 'styled-components';
// icons
import IconTelegram from 'assets/icons/telegram.svg';
import IconWatsApp from 'assets/icons/whatsapp.svg';

const SocialContacts = () => (
  <StyleSocialContacts>
    <a href="https://t.me/custommasters" rel="noreferrer" target="_blank">
      <IconTelegram className="icon" />
    </a>
    <a href="https://wa.me/message/NASAIM4BLZCZG1" rel="noreferrer" target="_blank">
      <IconWatsApp className="icon" />
    </a>
  </StyleSocialContacts>
);

export default SocialContacts;

const StyleSocialContacts = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;
  
  a + a {
    margin-left: 20px;
  }
  
  svg {
    height: 24px;
    width: 24px;
  }
`;
