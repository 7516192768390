// libraries
import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
// images
import logo from 'assets/images/logo.png';
// components
import SocialContacts from 'components/shared/SocialContacts';

const Header = () => (
  <StyledHeader>
    <div className="content">
      <div className="menu-wrapper">
        <StyledLink className="logotype" to="/">
          <img alt="" src={logo} />
          Custom
          {' '}
          <br />
          {' '}
          Masters
        </StyledLink>
      </div>

      <div className="contacts">
        <SocialContacts />

        <a className="phone" href="tel:+79533542968">
          +7 953 354 29 68
        </a>
      </div>
    </div>
  </StyledHeader>
);

export default Header;

const StyledLink = styled(Link)`
    img {
      margin-right: 5px;
    }
    
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #ffffff;
    font-size: 22px;
    font-weight: 700;
`;

const StyledHeader = styled.div`
  background-color: #DA0200;
  color: #fff;
  padding-bottom: 14px;
  padding-top: 13px;
  position: relative;
  
  &::after {
    background: linear-gradient(90deg, #DA0200 0%, #EF7C7C 100%);
    content: '';
    bottom: 0;
    height: 1px;
    left: 0;
    right: 0;
    position: absolute;
    display: block;
  }

  .menu,
  .menu-wrapper,
  .content,
  .contacts,
  .social-links {
    display: flex;
    align-items: center;
  }
  
  .content {
    justify-content: space-between;
    
    @media screen and (max-width: 1023px) {
      padding: 0 15px; 
    }  
  }
  
  .logotype {
    img + img {
      margin-left: 12px;
    }
    
    img:first-of-type {
      position: relative;
      top: 4px;
    }
  }
  
  .button {
    font-weight: 600;
    font-size: 18px;
    margin-left: 63px;
  }
  
  .menu {
    list-style: none;
    padding: 0;
    margin: 0 0 0 37px;
    
    li {
      padding: 0 48px;
    }
    
    a {
      color: #fff;
      font-style: normal;
      font-weight: 600;
      text-decoration: none;
    }
  }
  
  .phone {
    color: #fff;
    margin-left: 20px;
    text-decoration: none;
    font-size: 24px;
    line-height: 33px;
    
    @media screen and (max-width: 1023px) {
      display: none; 
    }  
  }

  .contacts {   
    .icon {
      fill: #fff;
    }
  }
`;
